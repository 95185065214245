<template>
    <div>
        <b-container>
            <b-row>
                <b-col cols="12">
                    <div class="text-center pt-4">
                        <h2 class="text-primary font-weight-bold">Politique AML</h2>
                    </div>
                </b-col>
                <b-col cols="12">
                    <div class="text-justify pt-3">

                        <div class="pt-4">
                            <h2 class="text-primary font-weight-bold">Dispositions Générales</h2>
                        </div>
                        <p>
                            <strong>Winichange</strong> est une plateforme de E-ÉCHANGE détenue par la société
                            <strong>Godwin Corp. (GCORP)</strong>
                            une SARLU (société à responsabilité limitée unipersonnelle) dont le siège social est situé à
                            Bingerville
                            Akandje immeuble Marga. Constitué en vertu des lois de la République de Côte d’Ivoire.
                            Immatriculée
                            au Registre de Commerce et du Crédit Mobilier du Tribunal de Commerce d’Abidjan sous le ……….
                            Cette plateforme agit en tant qu'intermédiaire facilitant l'interopérabilité des solutions
                            de transfert
                            d'argent. Elle connecte les cryptomonnaies aux services de transfert mobile et bancaire en
                            Afrique et
                            dans le monde, permettant ainsi une utilisation fluide et sécurisée des fonds pour les
                            utilisateurs.
                            Les clients sont tenus de se conformer aux lois locales relatives à la prévention du
                            blanchiment
                            d'argent et du financement du terrorisme, ainsi qu'à toute autre loi ou réglementation
                            pertinente
                            concernant les opérations de <strong> Winichange.</strong>
                            Les clients doivent strictement se conformer aux politiques et procédures énoncées dans ce
                            document (ci-après dénommé "la Politique").
                            Winichange élabore cette politique et peut y apporter des modifications et des ajouts à sa
                            discrétion, en supervisant le respect de ses dispositions et exigences.
                            Les clients doivent lire la politique avant d'accepter les conditions générales de
                            Winichange. Le
                            consentement du client aux conditions générales, et par conséquent, l'engagement de
                            transactions
                            dans le système Winichange après acceptation des conditions générales, signifie
                            l'acceptation par
                            le client de toutes les dispositions de la version actuelle de cette politique.
                        </p>
                        <div class="pt-4">
                            <h2 class="text-primary font-weight-bold">Responsable de la Conformité</h2>
                        </div>
                        <p>
                            Pour superviser et exécuter les procédures prévues dans la politique, <strong>Winichange
                            </strong>nomme un
                            responsable de la conformité.
                            Le Responsable de Conformité est chargé de collecter, d'analyser et d'enquêter sur les
                            informations
                            concernant toute activité suspecte et la conduite des employés de l'entreprise en ce qui
                            concerne les
                            procédures pertinentes. Le responsable des services aux commerçants établit les directives
                            et règles
                            pour l'identification des clients, l'examen et la surveillance des transactions
                            inhabituelles, ainsi que
                            les aspects techniques de la mise en œuvre de cette politique par Winichange.
                        </p>
                        <div class="pt-4">
                            <h2 class="text-primary font-weight-bold">Politique d'Identification du Client</h2>
                        </div>
                        <p>
                            Pour l'identification des clients, <strong>Winichange</strong> demande les documents suivants
                            :<br/>
                            - Un justificatif d'identité (passeport, permis de conduire, carte nationale d'identité)<br/>
                            - Un justificatif de domicile (relevé bancaire, facture de services publics)
                        </p>
                        <div class="pt-4">
                            <h2 class="text-primary font-weight-bold">Procedure KYC (Know Your Customer)</h2>
                        </div>
                        <p>
                            <strong>Winichange</strong> effectue les procédures de vérification KYC pour éviter le risque
                            d'être tenu
                            responsable et se protéger contre toute tentative d'utilisation de l'entreprise pour des
                            activités
                            illégales.Winichange évalue les transactions des clients et collecte et stocke des données
                            et des faits
                            essentiels relatifs aux clients, aux clients potentiels et à leurs transactions dans le
                            cadre des
                            procédures KYC.
                            Après l'identification d'un client, <strong>Winichange</strong> stocke les informations
                            obtenues dans le dossier du
                            client. La société s'engage à n'effectuer qu'une seule fois les démarches d'identification
                            liées à un
                            client.
                            Winichange s'engage à protéger les droits des clients et la confidentialité de leurs
                            données. Les
                            informations personnelles ne seront divulguées à des tiers que dans des circonstances
                            limitées,
                            conformément aux lois applicables et aux accords entre <strong>Winichange</strong> et le
                            client.
                        </p>
                        <div class="pt-4">
                            <h2 class="text-primary font-weight-bold">Identification et Détection des Activités
                                Suspectes</h2>
                        </div>
                        <p>
                            Toute transaction pouvant être liée à des activités de blanchiment d'argent sera considérée
                            comme
                            suspecte.
                            Les domaines permettant de déterminer qu'une transaction spécifique est suspecte peuvent
                            être les
                            vues et l'expérience personnelles des employés de la Société ainsi que les informations
                            reçues ou
                            identifiées.
                            Le responsable des services aux commerçants doit continuellement observer et mettre à jour
                            les
                            systèmes de l'entreprise pour détecter les activités suspectes.
                            Conformément aux lois applicables et aux exigences des organisations internationales, la
                            Société
                            peut, le cas échéant et sans obligation d'obtenir l'accord du Client ou d'en informer le
                            client, notifier
                            aux organismes de réglementation et/ou d'application de la loi toute transaction suspecte.
                            Winichange doit périodiquement se référer et consulter les listes publiées par les
                            responsables
                            locaux et les organisations internationales, qui comprennent des listes de terroristes
                            connus ou de
                            personnes soupçonnées d'activités terroristes, d'organisations terroristes, de pays à haut
                            risque, ainsi
                            qu'une liste limitée de pays soumis aux sanctions de l'OFAC
                        </p>
                        <div class="pt-4">
                            <h2 class="text-primary font-weight-bold">Tiers</h2>
                        </div>
                        <p>
                            <strong>Winichange</strong> peut faire appel à des prestataires de services tiers pour
                            certaines de ses fonctions
                            commerciales. Elle s'efforcera de déterminer, lors du processus de diligence raisonnable
                            initial et en
                            cours, s'il existe une enquête admise et des poursuites intentées contre de tels
                            prestataires de
                            services tiers.
                        </p>
                        <div class="pt-4">
                            <h2 class="text-primary font-weight-bold">Sanctions Civiles et Pénales</h2>
                        </div>
                        <p>
                            Les autorités gouvernementales de divers pays et, dans certains cas, les organisations
                            internationales
                            peuvent imposer de lourdes sanctions civiles et pénales à toute personne qui enfreint les
                            lois et
                            directives mentionnées dans la Politique.
                            Les entreprises peuvent être considérées comme pénalement responsables des actes de leurs
                            salariés dans certaines conditions.
                        </p>
                        <div class="pt-4">
                            <h2 class="text-primary font-weight-bold">Déclaration de Conformité</h2>
                        </div>
                        <p>
                            Le Client certifie qu'il a pris connaissance et a accepté cet avis, et qu'il fonctionnera en
                            totale
                            conformité avec les termes et normes décrits dans la Politique et se conformera à toutes les
                            lois et
                            autres lois applicables, ordonnances et conditions régissant ses activités en tant que
                            Client.
                            Le client confirme qu'il est responsable de ses actes conformément aux lois en vigueur dans
                            le
                            domaine abordé dans la présente politique et qu'il assumera la responsabilité en cas de
                            non-respect
                            de ces lois .
                        </p>




                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {

}
</script>

<style></style>
