<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="text-center pt-4">
            <h2 class="text-primary font-weight-bold">Nos conditions d'utilisation</h2>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="text-justify pt-3">

            <p>Il est recommandé de lire attentivement ces conditions d'utilisation avant d'accéder ou d'utiliser
              notre site. En accédant ou en utilisant n'importe quel aspect de notre site Web, vous consentez à être
              limité par ces conditions d'utilisation. Dans le cas où vous n'acceptez pas tous les termes et
              conditions de cet accord, vous ne pourrez alors pas accéder au site ni utiliser aucun service. Dans le
              cas où ces conditions de service sont considérées comme une offre, la reconnaissance est
              explicitement limitée à ces conditions de service.
            </p>
            <p>
              Toutes les fonctionnalités ou outils nouvellement ajoutés et répertoriés sur notre plateforme actuelle
              dépendront également des conditions d'utilisation. Vous pouvez consulter les conditions d'utilisation
              les plus récentes à tout moment sur cette page.
            </p>
            <p>
              Nous revendiquons toute autorité pour mettre à jour, modifier ou supplanter tout aspect de ces
              conditions d'utilisation en publiant des mises à jour ou des modifications potentielles sur notre site. Il
              est de votre seule responsabilité de vérifier cette page par intermittence pour les modifications
              apportées par nous. Vous êtes précédé de l'utilisation ou de l'admission au site Web après la
              publication de toute progression, ce qui établit la reconnaissance de ces modifications dans les
              termes et conditions.
            </p>


            <div class="pt-4">
              <h2 class="text-primary font-weight-bold">Restrictions d'âge</h2>
            </div>

            <p>
              En acceptant ces conditions d'utilisation, vous avez atteint l'âge d'éligibilité dans votre état ou votre
              résidence ou que vous avez atteint l'âge de la majorité dans votre état ou zone d'habitation et vous
              nous avez donné votre consentement pour permettre à l'un de vos pupilles mineurs d'utiliser ce site.
            </p>
            <p>
              Vous ne pouvez pas utiliser nos produits pour une raison illégale ou non approuvée et vous ne pouvez
              pas non plus, dans l'utilisation du Service, abuser des lois qui relèvent de votre compétence (y
              compris, mais sans se limiter aux lois sur le droit d'auteur).
            </p>
            <p>
              Vous ne devez communiquer aucun ver, bug ou code de nature ruineuse.
            </p>
            <p>
              Toute violation ou violation de l'une des Conditions entraînera la fin rapide de vos Services par
              <strong>Winichange</strong>.
            </p>


            <div class="pt-4">
              <h2 class="text-primary font-weight-bold">Conditions générales</h2>
            </div>

            <p>
              Nous conservons toute autorité pour refuser toute assistance à quiconque, de quelque manière que
              ce soit, à tout moment.
              Vous comprenez que votre contenu (à l'exclusion des données de carte de crédit) peut être transféré,
              décodé et inclus :
            <ul>
              <li>A. Transmissions sur différentes organisations ; et</li>
              <li>
                B. Modifications pour s'adapter et s'adapter aux nécessités spécialisées des organisations ou
                des gadgets d'interface. Les données des cartes de crédit sont constamment brouillées lors
                du transfert entre organisations.
              </li>
            </ul>


            Vous concluez un accord pour éviter de répéter, copier, dupliquer, vendre, effectuer une transaction
            ou aventurer toute partie du Service, l'utilisation du Service, ou l'admission au Service ou tout contact
            sur le site par lequel l'administration est donnée, sans consentement écrit exprès. Par nous
            </p>

            <div class="pt-4">
              <h2 class="text-primary font-weight-bold">Informations individuelles</h2>
            </div>

            <p>
              Tous les détails et informations personnels que vous envoyez via la plateforme sont représentés par
              notre politique de confidentialité. Vous pouvez consulter notre Politique de confidentialité pour en
              savoir plus à ce sujet.
            </p>

            <div class="pt-4">
              <h2 class="text-primary font-weight-bold">Utilisations restreintes</h2>
            </div>

            <p>
              Nonobstant les différents refus mentionnés dans les Conditions d'utilisation, il vous est interdit
              d'utiliser la page Web ou son contenu
            </p>

            <ul>
              <li>A. Pour toute raison illégale ;</li>
              <li>B. Demander à d’autres d’effectuer ou de participer à des manifestations illégales ;</li>
              <li>C. Ignorer les directives, règles, lois ou lois voisines mondiales, gouvernementales, courantes ou
                étatiques ;</li>
              <li>
                D. Empiéter sur ou ignorer nos droits d'innovation sous licence ou les privilèges d'innovation
                sous licence de tiers ;
              </li>
              <li>
                E. Pour soumettre des données fausses ou trompeuses ;
              </li>
              <li>
                F. Pour transférer ou envoyer des virus ou tout autre type de code malveillant qui sera ou
                pourrait être utilisé à quelque titre que ce soit qui influencera l'utilité ou l'activité du Service
                ou de tout site connecté, différents sites ou Internet
              </li>
              <li>
                G. Pour recueillir ou suivre les données individuelles d'autrui ;
              </li>
              <li>
                H. Pour spammer, phishing, pharm., déguisement, arachnide, ramper ou gratter ;
              </li>
              <li>
                I. Pour toute raison vulgaire ou inappropriée ; où
              </li>
              <li>
                J. Intervenir ou contourner les fonctionnalités de sécurité du Service ou de tout site connecté,
                différents sites ou Internet. Nous conservons toute autorité nécessaire pour mettre fin à
                votre utilisation du Service ou de tout site connecté pour abus de l'une des utilisations
                restreintes.
              </li>
            </ul>

            <div class="pt-4">
              <h2 class="text-primary font-weight-bold">Limitation de responsabilité</h2>
            </div>

            <p>
              Nous ne garantissons pas, ne déclarons pas et ne garantissons pas que votre utilisation de notre
              plateforme sera continue, idéale, sécurisée ou sans erreur.
            </p>
            <p>
              Nous ne garantissons pas que les résultats qui pourraient être obtenus grâce à l'utilisation de notre
              plateforme seront exacts ou solides.
            </p>
            <p>
              Vous convenez que nous pouvons occasionnellement éliminer la plateforme pour des délais non
              concluants ou abandonner la plateforme à tout moment, sans vous en aviser.
            </p>

            <div class="pt-4">
              <h2 class="text-primary font-weight-bold">Accord</h2>
            </div>

            <p>
              Notre déception de pratiquer ou de mettre en œuvre tout privilège ou arrangement des présentes
              conditions de service ne constituera pas une renonciation à ce droit ou à cet arrangement.
            </p>
            <p>
              Ces conditions d'utilisation et toutes les approches ou normes de travail publiées par nous sur ce site
              ou concernant le service comprennent l'ensemble de l'accord et de la compréhension entre vous et
              nous et supervisent votre mise en œuvre du service, supplantant tous les arrangements,
              correspondances et recommandations antérieurs ou contemporains. , que ce soit verbal ou écrit,entre vous
              et nous (en comptant, mais sans interdiction, toutes les formes antérieures des Conditions
              d'utilisation).
              Toute ambiguïté dans la traduction des présentes Conditions de Service ne sera pas interprétée à
              l'encontre de la partie rédactrice.
            </p>

            <div class="pt-4">
              <h2 class="text-primary font-weight-bold">Liens tiers</h2>
            </div>

            <p>Certaines substances, articles et services accessibles via notre service peuvent incorporer des
              matériaux provenant de tiers</p>

            <p>
              Les connexions de tiers sur cette page Web peuvent vous guider vers des sites tiers qui ne sont pas
              nos filiales. Nous ne sommes pas responsables de l'analyse ou de l'évaluation de la substance ou de
              l'exactitude et nous ne garantissons pas et n'aurons aucune obligation ou devoir concernant tout
              matériel ou site tiers, ou pour certains autres matériels, articles ou services de tiers.
            </p>

            <p>
              Nous ne courons aucun risque de méfait ou de préjudice lié à l'achat ou à l'utilisation de produits,
              services, actifs, contenus ou à certaines autres transactions effectuées concernant des sites tiers. Si
              cela ne vous dérange pas, examinez attentivement les approches et pratiques du tiers et assurez-vous
              de les comprendre avant de participer à une transaction. Les protestations, réclamations,
              préoccupations ou questions concernant les produits de tiers doivent être coordonnées au tiers.
            </p>



            <div class="pt-4">
              <h2 class="text-primary font-weight-bold">Résiliation</h2>
            </div>


            <p>
              <strong>Winichange</strong> peut, à notre discrétion, résilier ou interrompre l'accès à votre compte.
              L'accès peut
              être résilié pour plusieurs raisons, y compris, mais sans s'y limiter, la violation des conditions de
              services et une mauvaise utilisation. Aucun préavis n’est requis pour mettre fin à l’accès au service.
            </p>

            <p>
              Si vous souhaitez mettre fin à l'utilisation du service, vous devez en informer
              <strong>Winichange</strong>.
            </p>

            <p>
              Toutes les dispositions des Conditions qui, de par leur nature, devraient continuer à être résiliées,
              subiront la résiliation, y compris, sans limitation, les dispositions sur la propriété, les clauses de
              non-
              responsabilité, l'indemnisation et les limitations de responsabilité.
            </p>


            <div class="pt-4">
              <h2 class="text-primary font-weight-bold">Modification des frais</h2>
            </div>
            <p>
              Winichange, à sa seule discrétion et à tout moment, peut modifier les frais. Toute modification
              des frais entrera en vigueur immédiatement.
            </p>
            <p>
              Une fois que la modification des frais est entrée en vigueur, votre utilisation continue du Service
              regroupe votre accord de payer les frais modifiés.
            </p>

            <div class="pt-4">
              <h2 class="text-primary font-weight-bold">Changement</h2>
            </div>

            <p>
              À notre seule discrétion, nous avons le droit de réviser ou de remplacer ces conditions à tout
              moment. Si un changement est important, nous donnerons un préavis d'au moins 30 jours avant
              l'entrée en vigueur de toute nouvelle condition. Ce qui constitue un changement important sera
              conclu à notre seule discrétion.
            </p>

            <p>
              En reprenant l'accès ou l'utilisation de notre Service après l'entrée en vigueur des modifications, vous
              consentez à être lié par les conditions modifiées. Si vous ne vous conformez pas aux nouvelles
              conditions, vous n'êtes plus autorisé à utiliser le Service.
            </p>

            <div class="pt-4">
              <h2 class="text-primary font-weight-bold">Contactez-nous</h2>
            </div>

            <p>
              Si vous avez des questions concernant ces conditions, veuillez nous contacter.
            </p>

          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {

}
</script>

<style></style>