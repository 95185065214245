Kuraidesu, [16.03.21 09:47]
<template>
  <div class="sticky-top bg-success header">
    <div class="container">
      <b-navbar toggleable="lg" class="p-0" sticky>
        <b-navbar-brand href="/" class="logo text-success">
          <img src="@/assets/small-logo.png" height="60" width="60" alt="" srcset="">
          <span class="text-white title fw-bold"
            style="font-weight: 600; font-style: normal;font-size: 14px;">Winichange</span>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto menu">
            <!-- <b-nav-item to="/"><span class="item">{{ $t('header.home')}}</span></b-nav-item> -->
            <!-- <b-nav-item to="/about-us" v-scroll-to="'#about'" ><span class="font-weight-bold item">{{ $t('header.about') }}</span></b-nav-item> -->
            <b-nav-item href="/currencies"><span class="item">{{ $t('header.devices') }}</span></b-nav-item>
            <b-nav-item href="/#tarif"><span class="item">Nos tarifs</span></b-nav-item>
            <!-- <b-nav-item to="/simulate"><span class="font-weight-bold item">{{ $t('header.simulation') }}</span></b-nav-item> -->
            <b-nav-item href="/#support"><span class="item">Support</span></b-nav-item>
            <b-nav-item to="/faq"><span class="item">{{ $t('header.faq') }}</span></b-nav-item>
            <b-nav-item :href="loginUrl"><span class="item text-warning">Connexion</span></b-nav-item>
            <b-nav-item :href="registerUrl"><span class="item text-warning">S'inscrire</span></b-nav-item>
            <!-- <b-nav-item href="https://dashboard.godwinmultiservice.com"><span class="item">{{ $t('header.exchange') }}</span></b-nav-item> -->
            <!-- <b-nav-item to="/contact-us"><span class="font-weight-bold item">{{ $t('header.contact') }}</span></b-nav-item> -->
            <!-- <b-nav-item href="#">
              <b-icon icon="search" variant="success"></b-icon>
            </b-nav-item> -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isHovered: false,
      loginUrl: "http://go.winichange.com/login",
      registerUrl: "http://go.winichange.com/register",
    }
  },
  methods: {
    handleHover(hovered) {
      this.isHovered = hovered
    }
  }
}
</script>

<style lang="scss">
.menu .item {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: white;
  /* color: white; */
  /* identical to box height, or 20px */
}

.title {
  font-size: 14px;
}

.navbar-light .navbar-toggler {
  color: #000;
  border: none;
}
</style>