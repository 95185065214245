<template>
  <div id="app">
    <Header />
      <div class="backgroundPage">
        <router-view/>
      </div>
    <Footer />
    <scroll-up :scroll-duration="1000" :scroll-y="2000"></scroll-up>
  </div>
</template>

<script>
import Header from '@/components/layouts/Header.vue'
import Footer from '@/components/layouts/Footer.vue'
import ScrollUp from 'vue-scroll-up'
import 'vue-scroll-up/dist/style.css'

export default {
  components: {
    Header,
    Footer,
    ScrollUp
  }
}
</script>
<style>
#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vue-scroll-up {
  z-index: 99;
    height: 55px;
    width: 55px;
    position: fixed;
    border: #126e51;
    background-color: #126e51;
    color: #126e51;
    border-radius: 100px;
    left: 10px;
    bottom: 20px;
    cursor: pointer;
    outline: 0;
}

</style>
