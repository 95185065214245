<template>
  <div class="mt-3">
    <b-container>
      <div class="pt-2 text-center d-flex justify-content-center">
        <div class="align-items-center">
          <h4 class="font-weight-bold text-primary">{{ $t("home.testimony") }}</h4>
        </div>
      </div>

      <div class="mt-2">
        <b-row>
          <b-col sm="12" md="12" lg="6" class="p-2">
            <b-img :src="testimony" alt="testimony" fluid-grow />
          </b-col>
          <b-col sm="12" md="12" lg="6">
            <div class="h-100 d-flex flex-column justify-content-center">
              <div class="align-items-center">
                <b-img :src="testimony2" alt="testimony" fluid-grow />
                <b-img :src="testimony3" alt="testimony" fluid-grow />
                <b-img :src="testimon1" alt="" fluid-grow />
                <b-img :src="testimony1" alt="testimonys" fluid-grow />

                <!-- <carousel :autoplay-hover-pause="true" :per-page="1" pagination-color="#126e51" pagination-active-color="#E7A329">
                      <slide>
                            <b-card class="bg-transparent border-light">
                              <div class="">
                                <div class="text-center">
                                  <b-avatar variant="primary" size="5rem" text="KE" />
                                </div>
                              </div>
                              <div class="">
                                <div class="mt-5 text-justify">
                                  <q>
                                    Winichange est une plate-forme vraiment géniale...même étant en manuel ils assuraient les transactions 24h/24 et aujourd'hui Automatiquement j'imagine les merveilles qu'ils auront à nous offrir.  Vivement Godwin Multiservice.
                                  </q>
                                </div>
                              </div>
                              <div class="float-right">
                                <div class="mt-2">
                                  <cite>Côte d'ivoire</cite>
                                </div>
                              </div>
                            </b-card>
                      </slide>
                      <slide>
                            <b-card class="bg-transparent border-light">
                              <div class="">
                                <div class="text-center">
                                  <b-avatar variant="primary" size="5rem" text="AG" />
                                </div>
                              </div>
                              <div class="">
                                <div class="mt-5 text-justify">
                                  <q>Simple, rapide efficace, voilà en quelques mots l'expérience que j'ai eu avec la plateforme d'échange! Je recommande vivement son utilisation pour vos diverss transactions</q>
                                </div>
                              </div>
                              <div class="float-right">
                                <div class="mt-2">
                                  <cite>Sénégal</cite>
                                </div>
                              </div>
                            </b-card>
                      </slide>
                      <slide>
                            <b-card class="bg-transparent border-light">
                              <div class="">
                                <div class="text-center">
                                  <b-avatar variant="primary" size="5rem" text="YY" />
                                </div>
                              </div>
                              <div class="">
                                <div class="mt-5 text-justify">
                                  <q>
                                    J'utilise leurs services depuis peu et jusqu'ici tout fonctionne à la perfection et surtout depuis la nouvelle plateforme, tout se fait rapidement et de facon securisée.
                                  </q>
                                </div>
                              </div>
                              <div class="float-right">
                                <div class="mt-2">
                                  <cite>Côte d'Ivoire</cite>
                                </div>
                              </div>
                            </b-card>
                      </slide>
                    </carousel> -->
              </div>
            </div>
          </b-col>
        </b-row>
        <hr class="my-2 mt-5 bg-primary" />
      </div>
    </b-container>
  </div>
</template>

<script>
// import { Carousel, Slide } from 'vue-carousel';
import testimony from '../../assets/section/viral.svg'
import testimony1 from '../../assets/section/Temoignage1.png'
import testimony2 from '../../assets/section/Temoignage2.png'
import testimony3 from '../../assets/section/Temoignage3.png'

export default {
  name: 'Temoignage',
  components: {
    // Carousel,
    // Slide
  },
  data() {
    return {
      testimony,
      testimony1,
      testimony2,
      testimony3
    }
  }
}
</script>