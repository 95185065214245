<template>
  <div class="" id="about">
    <b-container>
      <div class="pt-1">
      </div>

      <b-row>
        <b-col sm="6" class="p-2">
          <!-- <div v-animate-css="'tada'" class="p-2">
            <b-img :src="about" fluid-grow />
          </div> -->
          <b-carousel id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000; border-radius:7px; border: 10px solid #126e51" fade indicators
            img-width="1024" img-height="480">
            <b-carousel-slide caption="" :img-src="banner1"></b-carousel-slide>
            <b-carousel-slide caption="" :img-src="banner2"></b-carousel-slide>
            <b-carousel-slide caption="" :img-src="banner3"></b-carousel-slide>
          </b-carousel>
        </b-col>
        <b-col sm="6" class="">
          <div class="h-100 d-flex flex-column justify-content-center">
            <div class="align-items-center text-justifys font-weight-light">
              Winichange est une plateforme d'interconnexion qui favorise l'interopérabilité des solutions de transfert
              d'argent en reliant les cryptomonnaies aux services de transfert mobile et bancaire en Afrique et dans le
              monde.
              <br><br>
              Notre objectif est de répondre à l'ensemble de vos besoins en matière d'échange et de transfert d'argent,
              en promouvant l'interopérabilité des systèmes
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="p-2 mt-2">
        <b-col sm="6" class="">
          <div class="h-100 d-flex justify-content-center">
            <div class="align-items-center mx-auto my-auto font-weight-light text-justifys">
              <h4 class="font-weight-bold text-primary mb-1">Une panoplie de solutions</h4><br>
              Notre service vous offre la flexibilité d'échanger de l'argent entre vos portefeuilles
              numériques, électroniques et mobiles. Nous facilitons également les transactions de
              transfert d'argent, tant au niveau national qu'international, permettant aux différentes
              solutions d'envoyer et de recevoir des fonds en toute simplicité.
            </div>
          </div>
        </b-col>
        <b-col sm="6">
          <b-img :src="transfert" alt="transfert" fluid-grow />
        </b-col>
      </b-row>
      <b-row class="p-2">

        <b-col sm="6" class="">
          <div class="h-100 d-flex justify-content-center">
            <div class="align-items-center mx-auto my-auto font-weight-light text-justifys">
              <h4 class="font-weight-bold text-success">Transfert sécurisé</h4><br>
              Nous assurons la sécurité de vos transactions afin qu’elles aboutissent à temps !
            </div>
          </div>
        </b-col>
        <b-col sm="6">
          <b-img :src="security" alt="speed" fluid-grow />
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col sm="6" class="">
          <div class="h-100 d-flex justify-content-center">
            <div class="align-items-center mx-auto my-auto font-weight-light text-justifys">
              <h4 class="font-weight-bold text-success">Transfert rapide</h4><br>
              Grâce à un système automatisé, nous nous efforçons à vous rendre un service très rapide !
            </div>
          </div>
        </b-col>
        <b-col sm="6">
          <b-img :src="speed" alt="transfert" fluid-grow />
        </b-col>
      </b-row>
      <div class="my-2" />
    </b-container>
  </div>
</template>

<script>
import speed from '../../assets/section/superhero.svg'
import security from '../../assets/section/secret.svg'
import transfert from '../../assets/section/transfer-money.svg'
import about from '../../assets/logo.svg'

import banner1 from '../../assets/banner/banner1.jpg'
import banner2 from '../../assets/banner/banner2.jpg'
import banner3 from '../../assets/banner/banner3.jpg'

export default {
  name: 'About',
  data() {
    return {
      speed,
      security,
      transfert,
      about,
      banner1,
      banner2,
      banner3
    }
  }
}
</script>

<style lang="scss">
.paragraph {
  font-weight: 600;
}
</style>