<template>
  <div class="container pt-5">
    <b-container>
      <div class="pt-2 text-center d-flex justify-content-center">
        <div class="align-items-center">
          <h4 class="font-weight-bold text-primary">{{$t("home.about-us") }} ?</h4>
        </div>
      </div>

      <b-row>
        <b-col sm="6" class="">
          <div v-animate-css="'tada'" class="p-2">
          </div>
        </b-col>
        <b-col sm="6">
          <div class="h-100 d-flex flex-column justify-content-center">
            <div class="align-items-center text-justify font-weight-light">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus animi totam exercitationem asperiores, suscipit libero, saepe iste quis, et nostrum nulla iure ipsam dicta eius quod quisquam blanditiis quo nihil.
              <br><br>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Necessitatibus animi totam exercitationem asperiores, suscipit libero, saepe iste quis, et nostrum nulla iure ipsam dicta eius quod quisquam blanditiis quo nihil.
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  components: {
  }
}
</script>
