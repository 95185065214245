import Vue from "vue";

import axios from "axios";

const axiosIns = axios.create({
  baseURL: "https://api.winichange.com/api",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  },
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
