/* eslint-disable vue/no-parsing-error */
<template>
	<div class="container pt-5">
    <div class="mb-3 text-center d-flex flex-column justify-content-center">
        <div class="align-items-center">
          <h4 class="font-weight-bold text-primary">Moyens de change</h4>

          <!-- <div>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laudantium, laboriosam id accusantium nobis voluptatem doloremque atque corrupti soluta mollitia facilis, molestiae consequuntur, reprehenderit quas ipsum. Ipsum temporibus eaque nesciunt quas?
          </div> -->
        </div>
      </div>
		<b-row class="mt-5">
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <h4 class="text-primary font-weight-bolder">Cryptomonnaie</h4>
            <div class="align-items-center text-justifys font-weight-light">
              Une cryptomonnaie est une devise électronique, ou virtuelle, car elle n'a aucune forme physique. Elle s'échange de pair à pair sur un système informatique décentralisé, ou blockchain, tenu à jour en permanence et (réputé) inviolable.
              Nous échangeons plusieurs cryptomonnaies sur notre plateforme.
            </div>
          </div>
      </b-col>
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <div class="align-items-center text-center">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide>
                <div class="d-flex justify-content-center">
                  <b-img src="@/assets/changes/images/btc2.svg" height="150" width="150" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="d-flex justify-content-center">
                  <b-img src="@/assets/changes/images/bitcoin-cash-bch-logo.svg" height="150" width="150" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="d-flex justify-content-center">
                  <b-img src="@/assets/changes/images/binance-usd-busd-logo.svg" height="150" width="150" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="d-flex justify-content-center">
                  <b-img src="@/assets/changes/images/binance-coin-bnb-logo.svg" height="150" width="150" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="d-flex justify-content-center">
                  <b-img src="@/assets/changes/images/binance-smart-chain.svg" height="150" width="150" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="d-flex justify-content-center">
                  <b-img src="@/assets/changes/images/dogecoin-doge-logo.svg" height="150" width="150" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="d-flex justify-content-center">
                  <b-img src="@/assets/changes/images/tron-trx-logo.svg" height="150" width="150" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="d-flex justify-content-center">
                  <b-img src="@/assets/changes/images/tether-usdt-logo.svg" height="150" width="150" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="d-flex justify-content-center">
                  <b-img src="@/assets/changes/images/shiba-inu-shib-logo.svg" height="150" width="150" />
                </div>
              </swiper-slide>
            </swiper>
            <div class="mt-2 mb-2">
              <b-button variant="primary" @click="redirect">Echanger</b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
     <hr class="my-2 bg-primary" />
    <b-row class="mt-5 mb-5">
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <h4 class="text-primary font-weight-bolder">Perfect Money</h4>
            <div class="align-items-center text-justifys font-weight-light">
              {{$t("page.currencies.perfect.paragraph1")}}
              <br>
              <br>
              {{$t("page.currencies.perfect.paragraph2")}}
              <br>
              <br>
              {{$t("page.currencies.perfect.paragraph3")}}
              <!-- <br>
              <br>
              {{$t("page.currencies.perfect.paragraph4")}} -->
            </div>
          </div>
          
      </b-col>
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <div class="align-items-center text-center">
            <b-img-lazy
              :src="perfect"
              fluid
            >
            </b-img-lazy>
            <div class="mt-2 mb-2">
              <b-button variant="primary" @click="redirect">Echanger</b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="my-2 bg-primary" />
		<b-row class="mt-5 mb-5">
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <h4 class="text-primary font-weight-bolder">Payeer</h4>
            <div class="align-items-center text-justifys font-weight-light">
              {{$t("page.currencies.payeer.paragraph")}}
            </div>
          </div>
      </b-col>
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <div class="align-items-center text-center pt-3">
            <b-img
              :src="payeer"
              fluid
            >
            </b-img>
            <div class="mt-2 mb-2">
              <b-button variant="primary" @click="redirect">Echanger</b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="my-2 bg-primary" />
    <b-row class="mt-5">
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
            <h4 class="text-primary font-weight-bolder">Orange Money</h4>
            <div class="align-items-center text-justifys font-weight-light">
              Orange Money est le portefeuille mobile proposé par le réseau Orange pour ses abonnés.
              Vous pouvez retirer ou envoyer de l'argent depuis Orange Money dans les pays ci après :
              <span class="text-primary font-weight-bold">Côte d'Ivoire, Mali, Burkina Faso, Guinée, Sénégal, Cameroun.</span> 
            </div>
          </div>
      </b-col>
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <div class="align-items-center text-center">
            <b-img
              :src="orange"
              class="img-fluid"
            >
            </b-img>
            <div class="mt-2 mb-2">
              <b-button variant="primary" @click="redirect">Echanger</b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="my-2 bg-primary" />
		<b-row class="mt-5 mb-5">
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <h4 class="text-primary font-weight-bolder">Moov Money</h4>
            <div class="align-items-center text-justifys font-weight-light">
              Moov Money ou Mobicash en fonction du pays est le portefeuille mobile proposé par le réseau Moov Africa pour ses abonnés.
              Vous pouvez retirer ou envoyer de l'argent depuis Moov Money ( Mobicash ) dans les pays ci après :
              <span class="text-primary font-weight-bold">Côte d'Ivoire, Mali, Burkina Faso.</span> 
            </div>
          </div>
      </b-col>
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <div class="align-items-center text-center">
            <b-row>
              <b-col>
                <b-img
                  :src="moov"
                  fluid
                  width="150"
                  height="150"
                >
                </b-img>
              </b-col>
              <b-col sm="6">
                <b-img
                  :src="mobicash"
                  fluid
                  width="150"
                  height="150"
                >
                </b-img>
              </b-col>
            </b-row>
            <div class="mt-2 mb-2">
              <b-button variant="primary" @click="redirect">Echanger</b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="my-2 bg-primary" />
		<b-row class="mt-5 mb-5">
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <h4 class="text-primary font-weight-bolder">Mtn Mobile Money</h4>
            <div class="align-items-center text-justifys font-weight-light">
              MTN Mobile Money est un service électronique sécurisé qui permet aux détenteurs de portefeuilles mobile MTN de garder, envoyer ou recevoir de l'argent, d'effectuer des paiements ainsi que d’autres transactions, simplement à l'aide de leur téléphone mobile. C'est rapide, simple, pratique et abordable. Ce service est proposé par MTN en partenariat avec plus de 10 banques dans de nombreux pays d'Afrique.
              Vous pouvez retirer ou envoyer de l'argent depuis Mtn Mobile Money dans les pays ci après :
              <span class="text-primary font-weight-bold">Côte d'Ivoire, Mali, Cameroun, Guinée, Burkina Faso.</span> 
            </div>
          </div>
      </b-col>
      <b-col sm="6">
        <div class="h-100 d-flex justify-content-center">
          <div class="align-items-center text-center">
            <b-row>
              <b-col>
                <b-img
                  :src="momo"
                  fluid
                  width="250"
                  height="250"
                >
                </b-img>
                <div class="mt-2 mb-2">
                  <b-button variant="primary" @click="redirect">Echanger</b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="my-2 bg-primary" />
		<!-- <b-row class="mt-5 mb-5">
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <h2 class="text-primary font-weight-bolder">Yup</h2>
            <div class="align-items-center text-justify font-weight-light">
              YUP, un porte-monnaie électronique 100% simple, sécurisé et disponible quel que soit le type de téléphone et l’opérateur de téléphonie mobile.              
              Nous vous donnons la possibilité d'echanger de YUP exclusivement depuis le <span class="text-primary font-weight-bold">Burkina Faso</span> vers d'autres pays.
            </div>
          </div>
      </b-col>
       <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <div class="align-items-center text-center">
            <b-img
              :src="yup"
              class="img-fluid"
            >
            </b-img>
            <div class="mt-2 mb-2">
              <b-button variant="primary" @click="redirect">Echanger</b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="my-2 bg-primary" /> -->
    <b-row class="mt-5 mb-5">
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
            <h4 class="text-primary font-weight-bolder">Wave</h4>
            <div class="align-items-center text-justifys font-weight-light">
              Votre partenaire mobile money, avec des taux de transfert de 1% sur le plan national et de 1.5% à l'international. Disponible uniquement au <span class="text-primary font-weight-bold">Sénégal et en Côte d'Ivoire</span>
            </div>
          </div>
      </b-col>
      <b-col sm="6">
        <div class="h-100 d-flex flex-row justify-content-center">
          <div class="align-items-center text-center">
            <b-img
              :src="wave"
              fluid
              height="250"
              width="250"
            >
            </b-img>
            <div class="mt-2 mb-2">
              <b-button variant="primary" @click="redirect">Echanger</b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="my-2 bg-primary" />
    <b-row class="mt-5 mb-5">
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
            <h4 class="text-primary font-weight-bolder">Free Money</h4>
            <div class="align-items-center text-justifys font-weight-light">
              Free Money à domicile offre aux clients la possibilité de faire des opérations de dépôts, retraits d’argent mais également de déplafonnement de leur compte Free Money sans se déplacer. Il est uniquement disponible au <span class="text-primary font-weight-bold">Sénégal</span>.          </div>
          </div>
      </b-col>
      <b-col sm="6">
        <div class="h-100 d-flex flex-row justify-content-center">
          <div class="align-items-center text-center">
            <b-img
              :src="freemoney"
              fluid
              height="250"
              width="250"
            >
            </b-img>
            <div class="mt-2 mb-2">
              <b-button variant="primary" @click="redirect">Echanger</b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="my-2 bg-primary" />
    <b-row class="mt-5 mb-5">
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
            <h4 class="text-primary font-weight-bolder">E-Money</h4>
            <div class="align-items-center text-justifys font-weight-light">
              E-Money, un service de <span class="font-weight-bold text-primary">BSIC Sénégal</span>, est un porte-monnaie électronique ne nécessitant pas de compte bancaire, il est accessible à tous et partout. Les clients EXPRESSO ont la possibilité d'effectuer des transactions financières.
            </div>
          </div>
      </b-col>
      <b-col sm="6">
        <div class="h-100 d-flex flex-row justify-content-center">
          <div class="align-items-center text-center">
            <b-img
              :src="emoney"
              fluid
              height="250"
              width="250"
            >
            </b-img>
            <div class="mt-2 mb-2">
              <b-button variant="primary" @click="redirect">Echanger</b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr class="my-2 bg-primary" />
		<b-row class="p-2">
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <h4 class="text-primary font-weight-bolder">Carte VISA</h4>
            <div class="align-items-center text-justifys font-weight-light">
              Nous vous donnons la possiblité de recharger votre <span class="text-danger">carte visa UBA</span> uniquement en <span class="text-primary">Côte d'Ivoire</span> depuis n'importe quel moyen d'échange.
            </div>
          </div>
      </b-col>
      <b-col sm="6">
        <div class="h-100 d-flex flex-column justify-content-center">
          <div class="align-items-center text-center">
            <b-img
              :src="visa"
              fluid
              height="350"
              width="350"
            >
            </b-img>
            <div class="mt-2 mb-2">
              <b-button class="form-control" variant="primary" @click="redirect">Recharger maintenant</b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'
// import { KinesisContainer, KinesisElement } from "vue-kinesis";
import btc from '@/assets/changes/images/btc2.svg'
import orange from '@/assets/changes/images/orange.svg'
import moov from '@/assets/changes/images/moov.svg'
import mobicash from '@/assets/changes/images/mobicash.png'
import momo from '@/assets/changes/images/momo.svg'
import yup from '@/assets/changes/images/yup.svg'
import wave from '@/assets/changes/images/wave.png'
import perfect from '@/assets/changes/images/pm.svg'
import payeer from '@/assets/changes/images/payeer.svg'
import visa from '@/assets/changes/images/visa.svg'
import emoney from '@/assets/changes/images/emoney.png'
import freemoney from '@/assets/changes/images/freemoney.png'

export default {
  components: {
      Swiper,
      SwiperSlide
    },
  data() {
    return {
      orange,
      moov,
      mobicash,
      momo,
      yup,
      wave,
      visa,
      btc,
      perfect,
      payeer,
      emoney,
      freemoney,
      swiperOption: {
          // effect: 'flip',
          // grabCursor: true,
          effect: 'coverflow',
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: 2,
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows : true
          },
          pagination: {
            el: '.swiper-pagination'
          }
        }
    }
  },
  methods: {
    redirect() {
      window.location = "http://go.winichange.com"
    }
  }
};
</script>