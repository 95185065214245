<template>
  <div class="mt-3" id="currencies">
    <b-container>
      <div class="pt-5 pb-2 text-center d-flex justify-content-center">
        <div class="align-items-center">
          <h4 class="font-weight-bold text-primary">{{ $t("home.changes-way") }}</h4>
        </div>
      </div>
      <div class="example-3ds">
        <!-- <swiper class="swiper" :options="swiperOption"> -->

        <!-- <swiper-slide>
            <div class="d-flex justify-content-center">
              <b-img src="@/assets/changes/images/btc2.svg" height="200" width="200" />
            </div>
          </swiper-slide> -->

        <!-- <swiper-slide v-for="(item, index) in buyOptions" :key="index">
            <div class="d-flex justify-content-center">
              <b-img :src="item.icones" height="150" width="150" />
            </div>
          </swiper-slide> -->
        <div class="d-flex flex-wrap justify-content-center">
          <!-- <div class="container d-flexs  justify-content-center col-12"> -->
          <b-img v-for="(item, index) in cryptoOptions" :key="index" :src="item.icones" height="70" width="100"
            class="p-2" />
          <!-- </div> -->

          <!-- <div class="d-flexs  justify-content-center mt-2"> -->
          <b-img v-for="(item, index) in electroniqueOptions" :key="index" :src="item.icones" height="70" width="100"
            class="p-2" />
          <!-- </div> -->

          <!-- <div class="d-flexs  justify-content-center mt-2"> -->
          <b-img v-for="(item, index) in visaOptions" :key="index" :src="item.icones" height="70" width="100"
            class="p-2" />
          <!-- </div> -->

          <!-- <div class="d-flexs  justify-content-center mt-2"> -->
          <b-img v-for="(item, index) in mobileOptions" :key="index" :src="item.icones" height="70" width="100"
            class="p-2" />
          <!-- </div> -->
        </div>
        <div class="pt-5 pb-2 text-center d-flex justify-content-center">
          <div class="align-items-center">
            <h5 class="font-weight-bold text-primary">Démarrez votre</h5>
            <h4 class="font-weight-bold text-primary">Première Transaction</h4>
            <div class="mt-2 mb-2">
              <b-button variant="primary" @click="redirect">Lancez Vous</b-button>
            </div>
          </div>
        </div>

        <!-- <b-tr v-for="(item, index) in buyOptions" :key="index">
                <b-td>
                  <img
                    :src="item.icones"
                    class="mr-1 float-left"
                    width="25"
                    height="25"
                  >
                  {{ item.libelle }}
                </b-td>
                <b-td>{{ item.buy }} XOF</b-td>
              </b-tr> -->

        <!-- <swiper-slide>
              <b-img src="@/assets/changes/images/binance-usd-busd-logo.svg" height="200" width="200" />
          </swiper-slide>

          <swiper-slide>
              <b-img src="@/assets/changes/images/binance-coin-bnb-logo.svg" height="200" width="200" />
          </swiper-slide>

          <swiper-slide>
              <b-img src="@/assets/changes/images/binance-smart-chain.svg" height="200" width="200" />
          </swiper-slide>

          <swiper-slide>
              <b-img src="@/assets/changes/images/dogecoin-doge-logo.svg" height="200" width="200" />
          </swiper-slide>

          <swiper-slide>
              <b-img src="@/assets/changes/images/tron-trx-logo.svg" height="200" width="200" />
          </swiper-slide>

          <swiper-slide>
              <b-img src="@/assets/changes/images/tether-usdt-logo.svg" height="200" width="200" />
          </swiper-slide>

          <swiper-slide>
              <b-img src="@/assets/changes/images/shiba-inu-shib-logo.svg" height="200" width="200" />
          </swiper-slide>

          <swiper-slide>
            <b-img src="@/assets/changes/images/pm.svg" height="300" width="300" />
          </swiper-slide>
          <swiper-slide>
            <b-img src="@/assets/changes/images/payeer.svg" height="250" width="250" />
          </swiper-slide>
          <swiper-slide>
            <b-img src="@/assets/changes/images/orange.svg" height="200" width="200" />
          </swiper-slide>
          <swiper-slide>
            <b-img src="@/assets/changes/images/momo.svg" height="125" width="200" />
          </swiper-slide>
          <swiper-slide>
            <b-img src="@/assets/changes/images/moov.svg" height="150" width="200" />
          </swiper-slide>
          <swiper-slide>
            <b-img src="@/assets/changes/images/mobicash.png" height="150" width="200" />
          </swiper-slide>
          <swiper-slide>
            <b-img src="@/assets/changes/images/freemoney.png" height="200" width="200" />
          </swiper-slide>
          <swiper-slide>
            <b-img src="@/assets/changes/images/visa.svg" height="150" width="200" />
          </swiper-slide>
          <swiper-slide>
            <b-img src="@/assets/changes/images/wave.png" height="150" width="150" />
          </swiper-slide>
          <swiper-slide>
            <b-img src="@/assets/changes/images/emoney.png" height="150" width="150" />
          </swiper-slide> -->

        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
        <!-- </swiper> -->
      </div>
      <hr class="my-2 mt-5 bg-primary" />
    </b-container>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'swiper-example-3d-cube',
  title: '3D Cube effect',
  components: {
    // Swiper,
    // SwiperSlide
  },
  data() {
    return {
      currencies: [],
      swiperOption: {
        effect: 'cube',
        grabCursor: true,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true
        },
        cubeEffect: {
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94
        },
        pagination: {
          el: '.swiper-pagination'
        }
      }
    }
  },

  computed: {
    mobileOptions() {
      let data = []
      this.currencies.forEach(elm => {
        if (elm.category === "mobile") {
          data.push(elm)
        }
      });
      return data
    },
    cryptoOptions() {
      let data = []
      this.currencies.forEach(elm => {
        if (elm.category === "crypto") {
          data.push(elm)
        }
      });
      return data
    },
    electroniqueOptions() {
      let data = []
      this.currencies.forEach(elm => {
        if (elm.category === "electronique" && elm.dev_code !== "card") {
          data.push(elm)
        }
      });
      return data
    },
    visaOptions() {
      let data = []
      this.currencies.forEach(elm => {
        if (elm.dev_code === "card") {
          data.push(elm)
        }
      });
      return data
    }
  },
  methods: {
    async getCurrencies() {
      const all = []
      this.currencies = await this.$http.get(`/currencies/all?type=sale`)
        .then(res => {
          const { data } = res.data
          data.filter(item => {
            if (item.sale_status === true) {
              return all.push({ ...item, icones: item?.icones.replace("https://godwin-api.aikpeachile.tech/public", "https://api.winichange.com") })
            }
          })
          return all
        })
        .catch(() => {
          return []
        })
    },
    redirect() {
      window.location = "http://go.winichange.com"
    }
  },
  mounted() {
    this.getCurrencies()
  }
}
</script>

<style lang="scss" scoped>
.example-3d {
  position: relative;
  overflow: hidden;
  height: 360px;
  padding: 10px;
}

.swiper {
  width: 300px !important;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -150px;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 30;
    background: rgb(255, 190, 85);
    background: linear-gradient(207deg, rgba(255, 190, 85, 0.5578606442577031) 21%, rgba(18, 110, 81, 1) 100%);
    background-position: center;
    background-size: cover;
    color: white;
  }

  .swiper-pagination {
    ::v-deep .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: white;
    }
  }
}
</style>