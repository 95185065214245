import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/animate'
import './plugins/vue-3d'
import './plugins/vuelidate'
import './plugins/axios'
import './plugins/vue-carousel'

import i18n from '@/plugins/i18n'

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
