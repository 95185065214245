import Vue from 'vue'
import VueRouter from 'vue-router'

// WebSites Pages Component
import Home from '../views/Home'
import Contact from '../views/Contact'
import Currency from '../views/Currencies'
import AboutUs from '../views/About'
import FAQ from '../views/Faq'
import Simulation from '../views/Simulation'
import Confidentialite from '../views/Confidentialite'
import ConfidentialiteAml from '../views/ConfidentialiteAml'
import Condition from '../views/Condition'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/contact-us',
    name: 'contact',
    component: Contact
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutUs
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ
  },
  {
    path: '/currencies',
    name: 'currency',
    component: Currency
  },
  {
    path: '/simulate',
    name: 'simulate',
    component: Simulation
  },
  {
    path: '/politique-de-confidentialite',
    name: 'politique-de-confidentialite',
    component: Confidentialite
  },
  {
    path: '/politique-de-conformite-aml',
    name: 'politique-de-conformite-aml',
    component: ConfidentialiteAml
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: Condition
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
