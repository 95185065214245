<template>
  <div>
    <b-container class="pt-5">
      <div class="mb-3 text-center d-flex justify-content-center">
        <div class="align-items-center">
          <h1 class="font-weight-bold text-primary">{{$t("simulation.title") }}</h1>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>