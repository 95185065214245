<template>
  <div>
    <b-container class="pt-5">
      <div class="mb-3 text-center d-flex justify-content-center">
        <div class="align-items-center">
          <h4 class="font-weight-bold text-primary">{{ $t("faq.title") }}</h4>
        </div>
      </div>
      <b-row>
        <b-col sm="12" lg="5" md="5">
          <b-img :src="contact" alt="testimony" fluid-grow />
        </b-col>
        <b-col sm="12" lg="7" md="7">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 border-light">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-1 variant="outline-primary">Comment faire une transaction
                  ?</b-button>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    Pour effectuer une transaction, il faut :
                    <ul>
                      <li>Se connecter à son compte client</li>
                      <li>Sélectionner le menu "Echanger"</li>
                      <li>Suivre les indications sur la plateforme afin d’être débité de votre compte et de recevoir de
                        façon automatique votre paiement.</li>
                    </ul>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1 border-light">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="outline-primary">Que faire lorsqu’une transaction de
                  Bitcoin prend du temps ?</b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  Les transactions de crypto-monnaies prennent du temps car il faut qu’elles soient à 2 confirmations au
                  moins avant que le paiement ne passe sur notre compte. Cet état de chose est lié à la technologie
                  blockchain et il faut donc patienter un moment ou envoyer le ticket de la transaction au service
                  client en ligne pour plus d’assistance lorsqu'après deux niveaux de confirmation, vous êtes restés
                  sans suite de paiement.
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1 border-light">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-3 variant="outline-primary">Que faire lorsque votre compte a été
                  débité mais vous n’aviez pas été crédité en retour ?</b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    R - Pour toute transaction où votre compte a été débité et vous n’aviez pas été crédité en retour,
                    il faudra fournir au service client en ligne ou via <a
                      href="https://api.whatsapp.com/send?phone=+22549665503"><img
                        src="https://img.icons8.com/color/25/000000/whatsapp--v1.png" /></a> :
                    <ul>
                      <li>La preuve de votre paiement (une capture d’écran qui montre que vous aviez réellement envoyé
                        l’argent sur notre compte)</li>
                      <li>Le numéro avec lequel vous aviez validé le paiement</li>
                      <li>Le ticket de la transaction</li>
                      <li>L’adresse de réception sur laquelle vous aimeriez recevoir.</li>
                    </ul>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-1 border-light">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-4 variant="outline-primary">Que faire lorsque vous aviez mis une
                  adresse de réception erronée ?</b-button>
              </b-card-header>
              <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    Il est important de faire attention aux erreurs du genre néanmoins, pour corriger une adresse
                    erronée il vous faut envoyer le numéro de votre ticket et l’adresse correcte au service client en
                    ligne via le support ou via whatsapp.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col sm="12" lg="7" md="7">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 border-light">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-5 variant="outline-primary">Quel est le minimum pour effectuer les
                  achats sur le site ?</b-button>
              </b-card-header>
              <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    Le minimum requis est l’équivalent en FCFA de 5$ sur la plateforme.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1 border-light">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-6 variant="outline-primary">Que vaut 1 dollar sur votre site
                  ?</b-button>
              </b-card-header>
              <b-collapse id="accordion-6" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    Nous avons une multitude de possibilités de transactions. Au risque de vous fournir des réponses
                    erronées, il faut toujours consulter la calculatrice du site pour des estimations assez précises en
                    temps réel.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1 border-light">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-7 variant="outline-primary">Comment faire la vérification du compte
                  client (KYC) ?</b-button>
              </b-card-header>
              <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    Vérifier son compte se fait en trois étapes:
                    <ul>
                      <li>vérifier son identité avec une pièce valide donc qui porte les nom et prénoms renseignés lors
                        de votre inscription;</li>
                      <li>envoyer un selfie qui nous permet de savoir que vous existez réellement;</li>
                      <li>envoyer un document qui permet de vérifier une votre adresse (certificat de résidence, une
                        facture d’électricité qui porte votre nom ou qui vérifie que vous habitez dans tel ou tel
                        domicile, ou encore un relevé bancaire.</li>
                    </ul>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1 border-light">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-8 variant="outline-primary">L'instauration d'un moyen de paiement
                  dans les autres pays.</b-button>
              </b-card-header>
              <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    Nous travaillons activement pour l’intégration des moyens de paiement de votre pays et donc
                    progressivement nous allons favoriser les transactions dans votre pays via la plateforme.
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-1 border-light">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-9 variant="outline-primary">Pourquoi vérifier son compte sur la
                  plateforme ?</b-button>
              </b-card-header>
              <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    Ne vous inquiétez pas, les institutions chargées de régulation du secteur financier l’exigent. Alors
                    plusieurs clients comme vous se sont déjà conformés à la règle et font de nous une plateforme
                    sécurisée et fiable. Aussi le sérieux que nous ferons (vous et nous) face à cette règle permettrait
                    aux partenaires financiers qui, de par leurs appuis pourraient nous aider à avoir des tarifs
                    beaucoup plus attractifs. Winichange A PRIS DES MESURES AUX NORMES INTERNATIONALES POUR LA
                    VÉRIFICATION DE VOTRE COMPTE
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-1 border-light">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-10 variant="outline-primary">Que faire en cas de non disponibilité
                  d’une devise sur la plateforme ?</b-button>
              </b-card-header>
              <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-card-text>
                    Winichange offre un service d'achat et vente de monnaies électroniques donc elle fonctionne sur un
                    système d'offre et de la demande; il peut donc arriver des périodes où une devise manque à cause
                    d’une forte demande. Par ailleurs nous enlevons certaines devises aussi pour raison de maintenance.
                    Toutefois des mesures extraordinaires sont toujours prises pour améliorer la qualité de nos
                    prestations et nous vous prions de nous suivre sur nos canaux afin d’être informés de toutes ces
                    actualités .
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
        <b-col sm="12" lg="5" md="5">
          <b-img :src="task" alt="task" fluid />
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
import contact from '../assets/section/unknown.svg'
import task from '../assets/section/task.svg'

export default {
  data() {
    return {
      contact,
      task
    }
  }
}
</script>
