<template>
  <div class="font-weight-lighter">
    <Banner />
    <About />
    <Devise />
    <!-- <Simulation /> -->
    <Count />
    <Testimony />
    <Contact />
  </div>
</template>


<script>
// @ is an alias to /src
// import Banner from '@/components/section/Banner.vue'
// import Banner2 from '@/components/section/Banner2.vue'
import About from '@/components/section/About.vue'
import Devise from '@/components/section/Devise.vue'
// import Simulation from '@/components/section/Simulation.vue'
import Testimony from '@/components/section/Testimony.vue'
// import Count from '@/components/section/Count.vue'
import Contact from './Contact.vue'

export default {
  name: 'Home',
  components: {
    // Banner,
    About,
    Devise,
    // Count,
    Testimony,
    Contact,
    // Banner2
  }
}
</script>
