<template>
  <div class="h-100" id="support">
    <b-container class="pt-5">
      <div class="mb-3 text-center d-flex justify-content-center">
        <div class="align-items-center">
          <h4 class="font-weight-bold text-primary">{{ $t("support") }}</h4>
        </div>
      </div>
      <b-row>
        <!-- <b-col sm="5">
          <b-img-lazy src="@/assets/section/contact1.jpg" fluid-grow />
        </b-col> -->
        <b-col sm="12" md="12" lg="6" class="p-2">
          <div class="d-flex justify-content-center">
            <b-img style="border-radius:7px; border: 10px solid #126e51" width="350" height="350" :src="contact"
              alt="support" />
          </div>
        </b-col>

        <b-col sm="12" md="12" lg="6" class="p-2">
          <div class="h-100 d-flex justify-content-center">
            <div class="align-items-center mx-auto my-auto font-weight-light text-justifys">
              <h4 class="font-weight-bold text-success">Notre équipe dévouée du service client est à votre disposition
                24h/7</h4><br>
              Pour répondre à toutes vos questions et vous assister à chaque étape de votre parcours.
              <br />
              Rejoignez dès maintenant notre clientèle satisfaite et profitez d'une assistance sur mesure, prête à
              vous épauler à tout moment, en fonction de vos besoins.

              <div class="mt-2 mb-2">
                <b-button variant="primary">
                  <a variant="primary" href="mailto:support@winichange.com">Contactez-nous</a>
                </b-button>
              </div>
            </div>
          </div>
        </b-col>

        <!-- <b-col sm="12" md="12" lg="6" class="p-2">
          <div :class="animation">
            <b-card class="bg-light border-light">
              <b-form @submit.prevent="tryToSave">
                <b-row>
                  <b-col sm="12" md lg="6">
                    <b-form-group :label="$t('contact.name')">
                      <b-form-input
                        v-model="$v.support.name.$model"
                        :formatter="formatter"
                        :class="{
                          'is-invalid': $v.support.name.$error,
                        }"
                      />
                      <div
                        v-if="$v.support.name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.support.name.required">{{
                          $t("errors.required")
                        }}</span>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-form-group :label="$t('contact.email')">
                      <b-form-input
                        v-model="$v.support.email.$model"
                        :formatter="toLower"
                        :class="{
                          'is-invalid': $v.support.email.$error,
                        }"
                      />
                      <div
                        v-if="$v.support.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.support.email.required">{{
                          $t("errors.required")
                        }}</span>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group :label="$t('contact.title')">
                      <b-form-input
                        v-model="$v.support.title.$model"
                        :formatter="formatter"
                        :class="{
                          'is-invalid': $v.support.title.$error,
                        }"
                      />
                      <div
                        v-if="$v.support.title.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.support.title.required">{{
                          $t("errors.required")
                        }}</span>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" lg="12">
                    <b-form-group :label="$t('contact.message')">
                      <b-form-textarea
                        v-model="$v.support.message.$model"
                        :placeholder="$t('errors.minMessageLength')"
                        :class="{
                          'is-invalid': $v.support.message.$error,
                        }"
                        rows="8"
                        no-resize
                      />
                      <div
                        v-if="$v.support.message.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.support.message.required">{{
                          $t("errors.required")
                        }}</span>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12">
                    <div class="text-center">
                      <b-spinner
                        v-if="process"
                        variant="primary"
                      />
                    </div>
                    <div class="mt-2 row">
                      <div class="col-sm-6 p-2">
                        <b-button
                        variant="secondary"
                        class="form-control"
                        :disabled="process"
                        @click="resetForm"
                        >
                          {{ $t("reset") }}
                        </b-button>
                      </div>
                      <div class="col-sm-6 p-2">
                        <b-button
                          variant="primary"
                          class="form-control"
                          :disabled="process"
                          type="submit"
                        >
                          {{ $t('send') }}
                        </b-button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </div>
        </b-col> -->

      </b-row>

    </b-container>
  </div>
</template>


<script>
import contact from '../assets/section/support.png'

import {
  required,
  minLength,
} from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      animation: null,
      contact,
      support: {
        name: null,
        email: null,
        title: null,
        message: null,
        file: null,
      },
      process: null,
      error: null,
      success: null,
    }
  },
  validations: {
    support: {
      name: {
        required,
      },
      email: {
        required,
      },
      title: {
        required,
      },
      message: {
        required,
        minLength: minLength(20),
      },
    },
  },
  methods: {
    formatter(value) {
      return value.toUpperCase()
    },
    toLower(value) {
      return value.toLowerCase()
    },
    resetForm() {
      this.support = {
        name: null,
        email: null,
        title: null,
        message: null,
        file: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    handleFile(e) {
      [this.support.file] = e.target.files
    },
    tryToSave() {
      this.animation = null
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.animation = 'animate-wrong'
        return
      }
      this.process = true
      const data = JSON.stringify({
        name: this.support.name,
        title: this.support.title,
        email: this.support.email,
        message: this.support.message,
        file_name: ".",
      })
      this.$http.post(`https://godwin-api.aikpeachile.tech/api/request-support`,
        data
      )
        .then(() => {
          this.resetForm()
          this.process = false
          this.$bvToast.toast("Demande enrégistrée avec succès. Nous vous répondrons dans un bref délai", {
            title: "Support Winichange",
            variant: 'primary',
            solid: true
          })
        })
        .catch(() => {
          this.process = false
          this.$bvToast.toast("Une erreur est survenue lors de l'envoi, veuillez rééssayer", {
            title: "Support Winichange",
            variant: 'danger',
            solid: true
          })
        })
    },
  },
}
</script>

<style>
.animate-wrong {
  animation: wrong 0.4s ease-in-out;
}

@keyframes wrong {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(40px);
  }

  40% {
    transform: translateX(20px);
  }

  60% {
    transform: translateX(40px);
  }

  80% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>